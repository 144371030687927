
@font-face {
	font-family: "Arvo";
	src: local("Arvo"),
	url("./fonts/Arvo/Arvo-BoldItalic.ttf") format("truetype");
	font-weight: bold;
	font-style: italic, oblique;
}
@font-face {
	font-family: "Arvo";
	src: local("Arvo"),
	url("./fonts/Arvo/Arvo-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Arvo";
	src: local("Arvo"),
	url("./fonts/Arvo/Arvo-Italic.ttf") format("truetype");
	font-style: italic, oblique;
}

@font-face {
	font-family: "Arvo";
	src: local("Arvo"),
	 url("./fonts/Arvo/Arvo.ttf") format("truetype");
	}

	
@font-face {
	font-family: "ArchivoNarrow";
	src: local("ArchivoNarrow"),
	url("./fonts/ArchivoNarrow/ArchivoNarrow-BoldItalic.ttf") format("truetype");
	font-weight: bold;
	font-style: italic, oblique;
}
@font-face {
	font-family: "ArchivoNarrow";
	src: local("ArchivoNarrow"),
	url("./fonts/ArchivoNarrow/ArchivoNarrow-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "ArchivoNarrow";
	src: local("ArchivoNarrow"),
	url("./fonts/ArchivoNarrow/ArchivoNarrow-Italic.ttf") format("truetype");
	font-style: italic, oblique;
}

@font-face {
	font-family: "ArchivoNarrow";
	src: local("ArchivoNarrow"),
	 url("./fonts/ArchivoNarrow/ArchivoNarrow-Regular.ttf") format("truetype");
	}


@font-face {
	font-family: "PTSerif";
	src: local("PTSerif"),
	url("./fonts/PTSerif/PTSerif-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "PTSerif";
	src: local("PTSerif"),
	url("./fonts/PTSerif/PTSerif-Italic.ttf") format("truetype");
	font-style: italic, oblique;
}

@font-face {
	font-family: "PTSerif";
	src: local("PTSerif"),
	url("./fonts/PTSerif/PTSerif-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Roboto";
	src: local("Roboto"),
	url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Roboto";
	src: local("Roboto"),
	url("./fonts/Roboto/Roboto-Italic.ttf") format("truetype");
	font-style: italic, oblique;
}
	
@font-face {
	font-family: "Roboto";
	src: local("Roboto"),
	 url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "Swiss921BT";
	src: local("Swiss921BT"),
	 url("./fonts/Swiss921BT-RegularA.ttf") format("truetype");
}