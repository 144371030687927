*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: 'Arial Nova';
}

body {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: black;
}

::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #0085B4;
}

    ::-webkit-scrollbar-thumb:hover {
        background: #288bc5;
    }

.ck-editor__editable_inline {
    min-height: 150px;
}

.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

.ck.ck-content ul {
    padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
    list-style-type: decimal;
}

.ck.ck-content ol {
    padding-left: 40px;
}

:root {
    --ck-border-radius: 3px;
    --ck-color-base-foreground: #edededc3;
}

.reactCodeInput input::-webkit-outer-spin-button,
.reactCodeInput input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    border-radius: var(--ck-border-radius);
    border-color: var(--ck-color-base-border);
}

.ck.ck-dropdown__panel {
    max-height: 160px; /* or anything else, more likely ~300px or so */
    /*overflow-y: auto;*/
}

/* Firefox */
.reactCodeInput input[type="number"] {
    -moz-appearance: textfield;
}

strong, b, i, em{
    font-family: inherit !important;
}

.MuiTooltip-tooltip {
    font-size: 13px !important;
    color: black !important;
    border: 1px inset black !important;
  }  

.MuiTooltip-popper * {
    background-color: white !important;
}